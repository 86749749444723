import React, {useEffect, useState} from 'react';
import './ConnectingPeople.scss';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import PublicRoute from "./components/routing/PublicRoute";
import ProtectedRoute from "./components/routing/ProtectedRoute";
import HomePage from './pages/home/HomePage';
import LoginPage from './pages/login/LoginPage';
import init from "./services/initializationService";
import RegisterPage from "./pages/register/RegisterPage";
import EchoPage from "./pages/echo/EchoPage";
import NotificationContainer from "./components/notification/NotificationContainer";

function ConnectingPeople() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        init()
            .then(() => console.log("Initialization is finished"))
            .catch(error => console.error('Initialization failed:', error))
            .finally(() => setLoading(false));
    }, []);


    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="app">
            <NotificationContainer/>
            <header className="header">
                <div className="container">
                    <h1 className="header__title">Connecting People</h1>
                </div>
            </header>
            <Router>
                <main className="main">
                    <Routes>
                        <Route path="*" element={<Navigate replace to="/home"/>}/>
                        <Route path="/login" element={<PublicRoute element={<LoginPage/>}/>}/>
                        <Route path="/register" element={<PublicRoute element={<RegisterPage/>}/>}/>
                        <Route path="/home" element={<ProtectedRoute element={<HomePage/>}/>}/>
                        <Route path="/echo" element={<ProtectedRoute element={<EchoPage/>}/>}/>
                    </Routes>
                </main>
            </Router>
            <footer className="footer">
                <div className="container">

                </div>
            </footer>
        </div>
    );
}

export default ConnectingPeople;
