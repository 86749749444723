
export class SimpleRTCIceCandidate {
    public readonly candidate: string;
    public readonly sdpMLineIndex: number | null;
    public readonly sdpMid: string | null;

    constructor(iceCandidate: RTCIceCandidate) {
        this.candidate = iceCandidate.candidate;
        this.sdpMLineIndex = iceCandidate.sdpMLineIndex;
        this.sdpMid = iceCandidate.sdpMid;
    }
}
