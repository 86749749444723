import {AuthRequest, UserRegistrationRequest} from "../types/SecurityTypes";
import http from "./httpService";
import {AxiosResponse} from "axios";
import {sendMessage, subscribeToTopic} from "./webSocketService";
import {IMessage, StompSubscription} from "@stomp/stompjs";
import {TimeoutId} from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";

const HTTP_API_PREFIX = '/api/v1';
const WEBSOCKET_SEND_PREFIX = '/api/ws/send';
const WEBSOCKET_SUBSCRIBE_PREFIX = '/api/ws/topic';
const WEBSOCKET_SUBSCRIPTION_DEFAULT_TIMEOUT_MS = 5000;

// HTTP API

export async function sendLogin(credentials: AuthRequest): Promise<AxiosResponse<any, any>> {
    return http.post(HTTP_API_PREFIX + '/auth/login', credentials);
}

export async function sendLogout(): Promise<AxiosResponse<any, any>> {
    return http.post(HTTP_API_PREFIX + '/auth/logout');
}

export async function sendValidateToken(): Promise<AxiosResponse<any, any>> {
    return http.get(HTTP_API_PREFIX + '/auth/validate');
}

export async function sendRefreshToken(): Promise<AxiosResponse<any, any>> {
    return http.post(HTTP_API_PREFIX + '/auth/refresh');
}

export async function sendRegister(registrationRequest: UserRegistrationRequest): Promise<AxiosResponse<any, any>> {
    return http.post(HTTP_API_PREFIX + '/auth/register', registrationRequest);
}


export async function sendDefaultApi(): Promise<AxiosResponse<any, any>> {
    return http.get(HTTP_API_PREFIX + '/default/hello');
}

export async function sendAdminApi(): Promise<AxiosResponse<any, any>> {
    return http.get(HTTP_API_PREFIX + '/default/admin');
}


export async function sendChanelOpen(): Promise<AxiosResponse<any, any>> {
    return http.post(HTTP_API_PREFIX + '/chanel/open');
}

export async function sendChanelClose(chanelId: string): Promise<AxiosResponse<any, any>> {
    return http.post(HTTP_API_PREFIX + '/chanel/close/' + chanelId);
}


// WEBSOCKET API

export function websocketSend(path: string, body: any): void {
    sendMessage(WEBSOCKET_SEND_PREFIX + path, JSON.stringify(body));
}

export function websocketSubscribe(path: string, callback: (message: IMessage) => void): StompSubscription {
    const subscription = subscribeToTopic(WEBSOCKET_SUBSCRIBE_PREFIX + path, (message) => {
        clearTimeout(timeoutId);
        callback(message);
    });
    const timeoutId: TimeoutId = setTimeout(
        () => {
            if (subscription) {
                subscription.unsubscribe();
                console.warn(`Unsubscribed from ${WEBSOCKET_SUBSCRIBE_PREFIX + path} due to timeout`);
            }
        },
        WEBSOCKET_SUBSCRIPTION_DEFAULT_TIMEOUT_MS
    );

    return subscription;
}