import {init as initHttpService} from "./httpService"
import {init as initSecurityService} from "./securityService"
import {init as initWebSocketService} from "./webSocketService"
import {init as initChanelMessageFactory} from "../types/chanel/initChanelMessageFactory";

export async function init(): Promise<void> {
    initChanelMessageFactory();
    await initHttpService();
    await initSecurityService();
    await initWebSocketService();
}

export default init;