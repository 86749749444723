import {ChanelMessage} from './ChanelMessage';
import {ChanelMessageType} from './ChanelMessageType';

export class ChanelMessageSanityCheck2Out extends ChanelMessage {
    public readonly type = ChanelMessageType.SANITY_CHECK_2_OUT;
    public readonly content: string;

    constructor(data: any) {
        super();
        this.content = data.content;
    }

    getType(): ChanelMessageType {
        return this.type;
    }
    static fromJSON(data: any): ChanelMessageSanityCheck2Out {
        return new ChanelMessageSanityCheck2Out(data);
    }
}
