import {ChanelMessage} from './ChanelMessage';
import {ChanelMessageType} from './ChanelMessageType';

type ChanelMessageConstructor = {
    new(data: any): ChanelMessage;
    fromJSON(data: any): ChanelMessage;
};

export class ChanelMessageFactory {
    private static registry: Map<ChanelMessageType, ChanelMessageConstructor> = new Map();

    public static register(type: ChanelMessageType, constructor: ChanelMessageConstructor) {
        ChanelMessageFactory.registry.set(type, constructor);
    }

    public static createMessage(data: any): ChanelMessage {
        const type: ChanelMessageType = data.type;

        const constructor = ChanelMessageFactory.registry.get(type);
        if (!constructor) {
            throw new Error(`No registered constructor for type: ${type}`);
        }

        return constructor.fromJSON(data);
    }
}
