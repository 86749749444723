import React, {useEffect, useRef, useState} from "react";
import './EchoPage.scss';
import {useNavigate} from "react-router-dom";
import {sendChanelClose, sendChanelOpen, websocketSend, websocketSubscribe} from "../../services/apiService";
import {ChanelMessageTrickleIn} from "../../types/chanel/ChanelMessageTrickleIn";
import {StompSubscription} from "@stomp/stompjs";
import {ChanelMessageEchoRequest} from "../../types/chanel/ChanelMessageEchoRequest";
import {ChanelMessageFactory} from "../../types/chanel/ChanelMessageFactory";
import {ChanelMessageType} from "../../types/chanel/ChanelMessageType";
import {createNotification} from "../../services/notificationService";
import {Dispatch} from "redux";
import {useDispatch} from "react-redux";
import {ChanelMessageEchoResponse} from "../../types/chanel/ChanelMessageEchoResponse";
import {ChanelMessageEchoEvent} from "../../types/chanel/ChanelMessageEchoEvent";
import {ChanelMessageTrickleOut} from "../../types/chanel/ChanelMessageTrickleOut";
import {getEnvVariable} from "../../services/environmentService";
import {SimpleRTCIceCandidate} from "../../types/chanel/SimpleRTCIceCandidate";

function EchoPage() {
    const navigate = useNavigate();
    const dispatch: Dispatch = useDispatch();

    const [chanelId, setChanelId] = useState<string | null>(null);
    const [subscription, setSubscription] = useState<StompSubscription | null>(null);

    const localVideoRef = useRef<HTMLVideoElement | null>(null);
    const remoteVideoRef = useRef<HTMLVideoElement | null>(null);

    const localStreamRef = useRef<MediaStream | null>(null);
    const connectionRef = useRef<RTCPeerConnection | null>(null);
    const offerRef = useRef<RTCSessionDescriptionInit | null>(null);

    const [isStarted, setIsStarted] = useState(false);

    const accessMediaDevices = async (): Promise<MediaStream> => {
        try {
            return await navigator.mediaDevices.getUserMedia({video: true, audio: true});
        } catch (error) {
            console.error("Error accessing media devices:", error);
            createNotification(
                "error",
                "Cannot access camera and microphone. Please check your device settings.",
                dispatch
            );
            throw error;
        }
    }


    const startLocalMedia = async () => {
        if (isStarted) return;
        setIsStarted(true);

        try {
            const stream = await accessMediaDevices();
            localStreamRef.current = stream;
            if (localVideoRef.current) {
                localVideoRef.current.srcObject = stream;
            }

            const chanelResponse = await sendChanelOpen();
            const newChanelId = chanelResponse.data;
            setChanelId(newChanelId);

            const TURN_HOST = getEnvVariable('REACT_APP_TURN_HOST');
            const TURN_PORT = getEnvVariable('REACT_APP_TURN_PORT');
            const TURN_TYPE = getEnvVariable('REACT_APP_TURN_TYPE');
            const TURN_USER = getEnvVariable('REACT_APP_TURN_USER');
            const TURN_PASSWORD = getEnvVariable('REACT_APP_TURN_PASSWORD');
            const configuration = {
                iceServers: [
                    {
                        urls: `turn:${TURN_HOST}:${TURN_PORT}?transport=${TURN_TYPE}`,
                        username: TURN_USER,
                        credential: TURN_PASSWORD
                    }
                ]
            };
            const connection = new RTCPeerConnection(configuration);
            connectionRef.current = connection;

            const newSubscription = websocketSubscribe('/' + newChanelId, (response) => {
                try {
                    const parsedData = JSON.parse(response.body);
                    const chanelMessage = ChanelMessageFactory.createMessage(parsedData);

                    if (chanelMessage.getType() === ChanelMessageType.ECHO_RESPONSE) {
                        const echoResponse = chanelMessage as ChanelMessageEchoResponse;
                        const stub = echoResponse.stub;
                        console.log("stub = " + stub);
                        if (offerRef.current) {
                            connection.setLocalDescription(offerRef.current); //async
                        }
                    } else if (chanelMessage.getType() === ChanelMessageType.ECHO_EVENT) {
                        const echoEvent = chanelMessage as ChanelMessageEchoEvent;
                        const janusSdp = echoEvent.janusSdp;
                        const sessionDescription: RTCSessionDescriptionInit = {
                            type: 'answer',
                            sdp: janusSdp
                        };

                        connection.setRemoteDescription(sessionDescription); // async
                    } else if (chanelMessage.getType() === ChanelMessageType.TRICKLE_OUT) {
                        const trickleMessage = chanelMessage as ChanelMessageTrickleOut;
                        if (trickleMessage.completed) {
                            console.log("ICE-candidates gathering is finished!");
                        } else {
                            const iceCandidates = Array.from(trickleMessage.iceCandidates);
                            for (let i = 0; i < iceCandidates.length; i++) {
                                const iceCandidate = iceCandidates[i];
                                const candidate = new RTCIceCandidate(iceCandidate);
                                connectionRef.current?.addIceCandidate(candidate);
                            }
                        }
                    } else {
                        createNotification("error", "Unexpected message", dispatch);
                    }
                } catch (error) {
                    createNotification("error", "Error during subscription message handling", dispatch);
                }
            });
            setSubscription(newSubscription);

            connection.ontrack = (event) => {
                if (remoteVideoRef.current) {
                    remoteVideoRef.current.srcObject = event.streams[0];
                }
            };

            stream.getTracks().forEach((track) => {
                if (connectionRef.current) {
                    connectionRef.current.addTrack(track, stream);
                }
            });

            connection.onicecandidate = (event) => {
                if (event.candidate) {
                    const chanelMessage = new ChanelMessageTrickleIn({
                        iceCandidates: [new SimpleRTCIceCandidate(event.candidate)],
                        completed: false
                    });
                    websocketSend('/' + newChanelId, chanelMessage);
                }
            };

            connection.onicegatheringstatechange = () => {
                if (connection.iceGatheringState === 'complete') {
                    const chanelMessage = new ChanelMessageTrickleIn({
                        iceCandidates: [],
                        completed: true
                    });
                    websocketSend('/' + newChanelId, chanelMessage);
                }
            };

            const offer = await connection.createOffer();
            const chanelMessage = new ChanelMessageEchoRequest({userSdp: offer.sdp});
            offerRef.current = offer;
            websocketSend('/' + newChanelId, chanelMessage);

            connection.onnegotiationneeded = async () => {
                try {
                    const offer = await connection.createOffer();
                    const chanelMessage = new ChanelMessageEchoRequest({userSdp: offer.sdp});
                    offerRef.current = offer;
                    websocketSend('/' + newChanelId, chanelMessage);
                } catch (error) {
                    console.error("Error during negotiation:", error);
                }
            };

        } catch (error) {
            console.error("Start connection error:", error);
            createNotification("error", "Failed to start the connection.", dispatch);
            await stopLocalMedia();
        }
    };

    const stopLocalMedia = async () => {
        if (!isStarted) return;
        try {
            if (connectionRef.current) {
                connectionRef.current.onicecandidate = null;
                connectionRef.current.ontrack = null;
                connectionRef.current.onicegatheringstatechange = null;
                connectionRef.current.onnegotiationneeded = null;
                connectionRef.current.close();
                connectionRef.current = null;
            }
            if (chanelId) {
                await sendChanelClose(chanelId);
                setChanelId(null);
            }
            if (subscription) {
                subscription.unsubscribe();
                setSubscription(null);
            }
            if (localStreamRef.current) {
                localStreamRef.current.getTracks().forEach((track) => {
                    track.stop();
                });
                localStreamRef.current = null;
            }
            if (localVideoRef.current) {
                localVideoRef.current.srcObject = null;
            }
            if (remoteVideoRef.current) {
                remoteVideoRef.current.srcObject = null;
            }

            setIsStarted(false);
        } catch (error) {
            console.error("Stop connection error:", error);
            createNotification("error", "Failed to stop the connection.", dispatch);
        }
    };

    useEffect(() => {
        return () => {
            (async () => {
                await stopLocalMedia();
            })();
        };
    }, []);


    const handleBack = async () => {
        navigate('/home');
    };

    return (
        <div className="echo">
            <div className="echo__display">
                <div className="echo__display-block">
                    <video id="echo_video_item_local" autoPlay playsInline ref={localVideoRef}></video>
                </div>
                <div className="echo__display-block">
                    <video id="echo_video_item_remote" autoPlay playsInline ref={remoteVideoRef}></video>
                </div>
            </div>
            <div className="echo__button-block">
                <div className="echo__control-block">
                    <button className="echo__button-control" onClick={startLocalMedia} disabled={isStarted}>
                        Start
                    </button>
                    <button className="echo__button-control" onClick={stopLocalMedia} disabled={!isStarted}>
                        Stop
                    </button>
                </div>
                <button className="echo__button-back" onClick={handleBack}>Back</button>
            </div>
        </div>
    );
}

export default EchoPage;
