export enum ChanelMessageType {
    ERROR = "error",

    SANITY_CHECK_1_IN = "sanity-check-1-in",
    SANITY_CHECK_1_OUT = "sanity-check-1-out",
    SANITY_CHECK_2_IN = "sanity-check-2-in",
    SANITY_CHECK_2_OUT = "sanity-check-2-out",
    SANITY_CHECK_3_IN = "sanity-check-3-in",
    SANITY_CHECK_3_OUT = "sanity-check-3-out",

    TRICKLE_IN = "trickle-in",
    TRICKLE_OUT = "trickle-out",

    ECHO_REQUEST = "echo-request",
    ECHO_RESPONSE = "echo-response",
    ECHO_EVENT = "echo-event"
}
