import {ChanelMessage} from './ChanelMessage';
import {ChanelMessageType} from './ChanelMessageType';

export class ChanelMessageEchoRequest extends ChanelMessage {
    public readonly type = ChanelMessageType.ECHO_REQUEST;
    public readonly userSdp: string;

    constructor(data: any) {
        super();
        this.userSdp = data.userSdp;
    }

    getType(): ChanelMessageType {
        return this.type;
    }

    static fromJSON(data: any): ChanelMessageEchoRequest {
        return new ChanelMessageEchoRequest(data);
    }
}
