import {ChanelMessage} from './ChanelMessage';
import {ChanelMessageType} from './ChanelMessageType';

export class ChanelMessageEchoResponse extends ChanelMessage {
    public readonly type = ChanelMessageType.ECHO_RESPONSE;
    public readonly stub: string;

    constructor(data: any) {
        super();
        this.stub = data.stub;
    }

    getType(): ChanelMessageType {
        return this.type;
    }

    static fromJSON(data: any): ChanelMessageEchoResponse {
        return new ChanelMessageEchoResponse(data);
    }
}
