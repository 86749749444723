import {ChanelMessage} from './ChanelMessage';
import {ChanelMessageType} from './ChanelMessageType';

export class ChanelMessageError extends ChanelMessage {
    public readonly type = ChanelMessageType.ERROR;
    public readonly message: string;

    constructor(data: any) {
        super();
        this.message = data.message;
    }

    getType(): ChanelMessageType {
        return this.type;
    }

    static fromJSON(data: any): ChanelMessageError {
        return new ChanelMessageError(data);
    }
}
