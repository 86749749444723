import React from "react";
import './HomePage.scss';
import {useNavigate} from "react-router-dom";
import {apiAdmin, apiDefault, logout} from "../../services/securityService";
import {useDispatch} from 'react-redux';
import {Dispatch} from 'redux';
import {createNotification} from "../../services/notificationService";
import {StompSubscription} from "@stomp/stompjs";
import {sendChanelClose, sendChanelOpen, websocketSend, websocketSubscribe} from "../../services/apiService";
import {ChanelMessageFactory} from "../../types/chanel/ChanelMessageFactory";
import {ChanelMessageType} from "../../types/chanel/ChanelMessageType";
import {ChanelMessageError} from "../../types/chanel/ChanelMessageError";
import {ChanelMessageSanityCheck1Out} from "../../types/chanel/ChanelMessageSanityCheck1Out";
import {ChanelMessageSanityCheck2Out} from "../../types/chanel/ChanelMessageSanityCheck2Out";
import {ChanelMessageSanityCheck3Out} from "../../types/chanel/ChanelMessageSanityCheck3Out";
import {ChanelMessageSanityCheck1In} from "../../types/chanel/ChanelMessageSanityCheck1In";
import {ChanelMessageSanityCheck2In} from "../../types/chanel/ChanelMessageSanityCheck2In";
import {ChanelMessageSanityCheck3In} from "../../types/chanel/ChanelMessageSanityCheck3In";


function HomePage() {
    const navigate = useNavigate();
    const dispatch: Dispatch = useDispatch();

    const handleLogout = async () => {
        await logout(navigate, dispatch);
    }

    const handleMakeNotification = async () => {
        await sendChanelOpen()
            .then(response => {
                const chanelId: string = response.data;
                let messageCount = 0;

                let subscription: StompSubscription | null = null;
                subscription = websocketSubscribe('/' + chanelId, (response) => {
                    messageCount++;
                    const parsedData = JSON.parse(response.body);
                    const chanelMessage = ChanelMessageFactory.createMessage(parsedData);

                    if (chanelMessage.getType() === ChanelMessageType.ERROR) {
                        const errorMessage = chanelMessage as ChanelMessageError;
                        createNotification("warning", errorMessage.message, dispatch);
                    } else if (chanelMessage.getType() === ChanelMessageType.SANITY_CHECK_1_OUT) {
                        const checkMessage = chanelMessage as ChanelMessageSanityCheck1Out;
                        createNotification("success", checkMessage.content, dispatch);
                    } else if (chanelMessage.getType() === ChanelMessageType.SANITY_CHECK_2_OUT) {
                        const checkMessage = chanelMessage as ChanelMessageSanityCheck2Out;
                        createNotification("success", checkMessage.content, dispatch);
                    } else if (chanelMessage.getType() === ChanelMessageType.SANITY_CHECK_3_OUT) {
                        const checkMessage = chanelMessage as ChanelMessageSanityCheck3Out;
                        createNotification("success", checkMessage.content, dispatch);
                    } else {
                        createNotification("error", "Unexpected message", dispatch);
                    }

                    if (messageCount >= 3) {
                        if (subscription) {
                            subscription.unsubscribe();
                        }
                        sendChanelClose(chanelId);
                    }
                });

                const inputMessage1 = new ChanelMessageSanityCheck1In({content: 'Hello World!'});
                const inputMessage2 = new ChanelMessageSanityCheck2In({content: 'Hello World!'});
                const inputMessage3 = new ChanelMessageSanityCheck3In({content: 'Hello World!'});
                websocketSend('/' + chanelId, inputMessage1);
                websocketSend('/' + chanelId, inputMessage2);
                websocketSend('/' + chanelId, inputMessage3);
            })
    }

    const handleDefaultApi = async () => {
        await apiDefault(dispatch);
    }

    const handleAdminApi = async () => {
        await apiAdmin(dispatch);
    }

    const handleEcho = async () => {
        navigate('/echo');
    }

    return (
        <div className="home">
            <h2 className="home__title">Hello, World!</h2>
            <button className="home__logout" onClick={handleLogout}>Logout</button>
            <button className="home__notification" onClick={handleMakeNotification}>Make notification</button>
            <button className="home__api" onClick={handleDefaultApi}>Default API</button>
            <button className="home__api" onClick={handleAdminApi}>Admin API</button>
            <button className="home__echo" onClick={handleEcho}>Echo Test</button>
        </div>
    );
}

export default HomePage;