import {ChanelMessage} from './ChanelMessage';
import {ChanelMessageType} from './ChanelMessageType';
import {SimpleRTCIceCandidate} from "./SimpleRTCIceCandidate";

export class ChanelMessageTrickleIn extends ChanelMessage {
    public readonly type = ChanelMessageType.TRICKLE_IN;
    public readonly iceCandidates: Array<SimpleRTCIceCandidate>;
    public readonly completed: boolean;

    constructor(data: any) {
        super();
        this.iceCandidates = data.iceCandidates || [];
        this.completed = data.completed || false;
    }

    getType(): ChanelMessageType {
        return this.type;
    }

    static fromJSON(data: any): ChanelMessageTrickleIn {
        return new ChanelMessageTrickleIn(data);
    }
}
