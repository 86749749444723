import {ChanelMessage} from './ChanelMessage';
import {ChanelMessageType} from './ChanelMessageType';
import {SimpleRTCIceCandidate} from "./SimpleRTCIceCandidate";

export class ChanelMessageTrickleOut extends ChanelMessage {
    public readonly type = ChanelMessageType.TRICKLE_OUT;
    public readonly iceCandidates: Set<SimpleRTCIceCandidate>;
    public readonly completed: boolean;

    constructor(data: any) {
        super();
        this.iceCandidates = new Set(data.iceCandidates);
        this.completed = data.completed || false;
    }

    getType(): ChanelMessageType {
        return this.type;
    }

    static fromJSON(data: any): ChanelMessageTrickleOut {
        return new ChanelMessageTrickleOut(data);
    }
}
