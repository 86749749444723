import {ChanelMessage} from './ChanelMessage';
import {ChanelMessageType} from './ChanelMessageType';

export class ChanelMessageSanityCheck2In extends ChanelMessage {
    public readonly type = ChanelMessageType.SANITY_CHECK_2_IN;
    public readonly content: string;

    constructor(data: any) {
        super();
        this.content = data.content;
    }

    getType(): ChanelMessageType {
        return this.type;
    }

    static fromJSON(data: any): ChanelMessageSanityCheck2In {
        return new ChanelMessageSanityCheck2In(data);
    }
}
