import {ChanelMessageFactory} from './ChanelMessageFactory';
import {ChanelMessageType} from './ChanelMessageType';
import {ChanelMessageError} from './ChanelMessageError';
import {ChanelMessageSanityCheck1In} from './ChanelMessageSanityCheck1In';
import {ChanelMessageSanityCheck1Out} from './ChanelMessageSanityCheck1Out';
import {ChanelMessageSanityCheck2In} from './ChanelMessageSanityCheck2In';
import {ChanelMessageSanityCheck2Out} from './ChanelMessageSanityCheck2Out';
import {ChanelMessageSanityCheck3In} from './ChanelMessageSanityCheck3In';
import {ChanelMessageSanityCheck3Out} from './ChanelMessageSanityCheck3Out';
import {ChanelMessageTrickleIn} from './ChanelMessageTrickleIn';
import {ChanelMessageTrickleOut} from './ChanelMessageTrickleOut';
import {ChanelMessageEchoRequest} from "./ChanelMessageEchoRequest";
import {ChanelMessageEchoResponse} from "./ChanelMessageEchoResponse";
import {ChanelMessageEchoEvent} from "./ChanelMessageEchoEvent";

export function init() {
    ChanelMessageFactory.register(ChanelMessageType.ERROR, ChanelMessageError);
    ChanelMessageFactory.register(ChanelMessageType.SANITY_CHECK_1_IN, ChanelMessageSanityCheck1In);
    ChanelMessageFactory.register(ChanelMessageType.SANITY_CHECK_1_OUT, ChanelMessageSanityCheck1Out);
    ChanelMessageFactory.register(ChanelMessageType.SANITY_CHECK_2_IN, ChanelMessageSanityCheck2In);
    ChanelMessageFactory.register(ChanelMessageType.SANITY_CHECK_2_OUT, ChanelMessageSanityCheck2Out);
    ChanelMessageFactory.register(ChanelMessageType.SANITY_CHECK_3_IN, ChanelMessageSanityCheck3In);
    ChanelMessageFactory.register(ChanelMessageType.SANITY_CHECK_3_OUT, ChanelMessageSanityCheck3Out);
    ChanelMessageFactory.register(ChanelMessageType.TRICKLE_IN, ChanelMessageTrickleIn);
    ChanelMessageFactory.register(ChanelMessageType.TRICKLE_OUT, ChanelMessageTrickleOut);
    ChanelMessageFactory.register(ChanelMessageType.ECHO_REQUEST, ChanelMessageEchoRequest);
    ChanelMessageFactory.register(ChanelMessageType.ECHO_RESPONSE, ChanelMessageEchoResponse);
    ChanelMessageFactory.register(ChanelMessageType.ECHO_EVENT, ChanelMessageEchoEvent);
}
