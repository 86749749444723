import {ChanelMessage} from './ChanelMessage';
import {ChanelMessageType} from './ChanelMessageType';

export class ChanelMessageEchoEvent extends ChanelMessage {
    public readonly type = ChanelMessageType.ECHO_EVENT;
    public readonly janusSdp: string;

    constructor(data: any) {
        super();
        this.janusSdp = data.janusSdp;
    }

    getType(): ChanelMessageType {
        return this.type;
    }

    static fromJSON(data: any): ChanelMessageEchoEvent {
        return new ChanelMessageEchoEvent(data);
    }
}
